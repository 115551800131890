import { useEffect, useState } from 'react';
import React from 'react'
//import Carousel from 'react-grid-carousel'
import { useSpringCarousel } from 'react-spring-carousel'
import { useTranslation } from "react-i18next";
import themes from "../../themes.json";

const WeaponBanner = ({data, clientName}) => {
    // configuration, how you would like the weapons to be visually ordered in the banner, in (lower-case-form)
    const weaponOrders = ["archer", "freeze", "witch", "dragon", "peacemaker", "sword", "bomb", "thief", "raid", "shield"];
    const { t } = useTranslation();
    const [show, setShow] = useState('show');
    const [weapons, setWeapons] = useState(data);
    const [arrowBtn, setArrowBtn] = useState('');
    const [weapondesc,setWeapondesc] = useState(t("WEAPON_DESCRIPTION_PLACEHOLDER"));
    const [windowsInnerWidth, setWindowsInnerWidth] = useState(window.innerWidth);
    
    function debounce(fn, ms) {
        let timer
        return _ => {
          clearTimeout(timer)
          timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
          }, ms)
        };
      }

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setWindowsInnerWidth( window.innerWidth);
            for (let i = 0; i < 8; i++) {
                slideToPrevItem();
            }

        }, 50);
        
        window.addEventListener('resize', debouncedHandleResize)

            return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
            
        }
    },[])

    const getItemsPerSlide = () => {
        let numItems = data.length;
        if(windowsInnerWidth <= 340){
            numItems =  3;
        }

        if(windowsInnerWidth <= 410){
            numItems =  4;
        }

        if(windowsInnerWidth <= 460){
            numItems =  5;
        }

        if(!shouldShow8Items()){
            numItems =  6;
        }
        numItems = numItems > data.length ? data.length : numItems;
        return numItems;
    }

    const shouldShow8Items = () => {
        return  windowsInnerWidth > 1200 || (windowsInnerWidth <= 900 && windowsInnerWidth > 650);
    }

    const reOrderWeaponsItems = () => {


        for(let i = 0; i < weapons.length; i++) {
            for(let j = 0; j < weaponOrders.length; j++){
                if(weapons[i].name.toLowerCase() === weaponOrders[j]){
                    weapons[i].orderNum = j;
                }
            }
        }

        return weapons.sort((a, b) => {
            if (a.orderNum < b.orderNum) {
                return -1;
              } else if (a.orderNum > b.orderNum) {
                return 1;
              }
              // a.orderNum must be equal to b.orderNum, shouldn't haapen
              console.log("back-office configuration error, same weapons being put in the tournament");
              return 0;
        });
    }

    const itemsPerSlide = getItemsPerSlide();

    const { carouselFragment, slideToPrevItem, slideToNextItem, getCurrentActiveItem } = useSpringCarousel({
        slideAmount: 1,
        itemsPerSlide:  itemsPerSlide,
        disableGestures: false,
        withLoop: false,
        items: weapons && reOrderWeaponsItems().map((weapon,index) => ({
          id: index,
          renderItem: (
            <BattleItem name={weapon.name} clientName={clientName} totalUses={weapon.totalUses} lifeTime={weapon.lifeTime} desc={`${weapon.name.toUpperCase()}_DESC`} clickHandler={(val,lifeTime)=>showDescription(val,lifeTime)} />
             
            
          ),
        })),
      })

      const moveToPrevItem = () => {
        const currentId = getCurrentActiveItem().id;
        if(currentId == 0){
            // console.log(" no long item to slide back to " + currentId);
            return;
        }

        slideToPrevItem();
    }

    const moveToNextItem = () => {
        const currentId = getCurrentActiveItem().id;
        if(currentId == (weapons.length - itemsPerSlide)){
            // console.log(" no long item to slide to next " + currentId);
            return;
        }

        slideToNextItem();
    }

    const expandSection = () => {
        setWeapondesc('');
        if(show === 'hide'){
            setShow('show');
            setArrowBtn('down');
        }else{
            setShow('hide');
            setArrowBtn('');
        }
    }
    const showDescription = (val, lifetime)=>{
        let desc = val;
        if(desc.indexOf('[X]') > -1){
            desc = desc.replace('[X]', lifetime);
        }
        setWeapondesc(`${desc}`)
    }

    return( 
        <div id="gameweaponsbanner" className="card-section-expand pt-2">
            <div className="pb-2">
                <div className="card-row  ff-bold align-items-center">
                    <div className="col-12 no-gutters text-center">
                        <div className="card-section-title">{t("WEAPONS")}</div>
         
                        <div className="card-section-desc  weapon-description-text ">{weapondesc}</div>
                    </div>
                    {/*<div className="col-1 d-flex align-items-center justify-content-end">
                        <img className={`card-arrow ${arrowBtn}`} alt="arrowSVG" src={require('./../../assets/images/arrow.svg').default}/>   
                    </div>*/}
                </div>
            </div> 
            
            <div id="gameweapons" className="card-weapons" >
                <div className="carousel-container" style={{width:weapons.length > 4 ? "100%" : (weapons.length*16)+"%"}}>
                    <div className="carousel-holder">{carouselFragment}</div>
                    {/*weapons.length > 5 && !shouldShow8Items() &&
                    <div className="left-arrow top" onClick={moveToPrevItem}>
                        <img className={`card-arrow left`} alt="arrowSVG" src={require('./../../assets/images/arrow.svg').default}/>   
                    </div>
                }
                    {weapons.length > 5 && !shouldShow8Items() &&
                    <div className="right-arrow  top" onClick={moveToNextItem}>
                        <img className={`card-arrow right`} alt="arrowSVG" src={require('./../../assets/images/arrow.svg').default}/>   
                    </div>
                */ }
                </div>
                
                
            </div>
            
        </div>
    );
};
    
export default WeaponBanner;

const BattleItem = ({name,totalUses,lifeTime, clickHandler,clientName,desc}) =>{
    const { t } = useTranslation();
    let imgPath = "";
    let imgName = "";
    let imgDesc = "";

    if(themes && themes.games){

        let result  = themes.games.filter((e)=> e.name === clientName );

        if(result && result[0]){

            let item = result[0].weapons.filter((e)=> e.name === name.toString().toLowerCase());
            if(item && item[0]){
                if(item[0].path){
                    imgPath = process.env.PUBLIC_URL +`/assets/${item[0].path}`;
                    imgName = item[0].altname;
                    if(item[0].description){
                        imgDesc = item[0].description;
                    }
                }
            }
        }
    }
    if(imgPath === ""){
        imgPath = require(`../../assets/images/buttons/old/${name.toString().toLowerCase()}off.png`);
        
    }
    if(imgName === ""){
        imgName = name;
    }
    if(imgDesc === ""){
        imgDesc = desc;
    }

    imgDesc = t(`${imgName}`) +": "+ t(`${imgDesc}`);


    
    return (
        <div onClick={()=>clickHandler(imgDesc,lifeTime)}>
            {/*<div className="card-weapon-title">{t(name.toUpperCase())}</div>*/}
            <div className="card-weapon-container">
                <img className="card-weapon-img" alt={`weapon${imgName}`} src={imgPath} />
                {/*<img className="card-weapon-img" alt={`weapon${name}`} src={require(`../../assets/images/buttons/old/${name.toString().toLowerCase()}off.png`)} />*/}
                {/*totalUses.toString() === "0" ?
                    <div className="card-weapon-usage card-weapon-usage-top"><img alt="inifinate" src={require('../../assets/images/infinitySymbol.svg').default} className="infinitySymbol"/>   
                    </div>
                :
                    <div className="card-weapon-usage card-weapon-usage-top">{totalUses}</div>
                */}
            </div>
            
        </div>
    )
}   
const BattleItem1 = (props) =>{
    const {name,totalUses,lifeTime, clickHandler} = props;
    const { t } = useTranslation();
    return (
        <div onClick={clickHandler}>
            
            <div className="card-weapon-container">
                <img className="card-weapon-img" alt={`weapon${name}`} src={require(`./../../assets/sportsbook/images/buttons/${name.toString().toLowerCase()}off.png`)} />
                {/*<div className="card-weapon-usage">{totalUses}</div>*/}
            </div>
            <div className="card-weapon-title">{t(name.toUpperCase())}</div>
            
        </div>
    )
}