import React from "react";
const Maintenance = () => {
    return (
        <div className="container-page">
            <div className="content-page">
                <h5>Down For Maintenance</h5>
            </div>
        </div>
    );
};
  
export default Maintenance;