import React,{useLayoutEffect, useRef, useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import UserContext from '../../generic/components/appContext';
import axios from "axios";

const LeaderboardBannerSide = ({data}) => {
    const [isShowWeekly, setIsShowWeekly] = useState(true);
    let [gameId, setGameId] = useState(data.id ? data.id : null);
    const {userId,databaseURL} = useContext(UserContext);
    let [awards, setAwards] = useState(data.awards ? data.awards : []);
    //const [leaderboardData, setLeaderboardData] = useState(data.leaderboard ? data.leaderboard : []);
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [highlightPlayer, setHighlightPlayer] = useState('');
    let [page, setPage] = useState(1);
    let [pageCount, setPageCount] = useState(100);
    const [finalPage, setFinalPage] = useState(1);
    const leaderboardRef = useRef(null); 
    const [gettingData,setGettingData] = useState(false);
    const { t } = useTranslation();
    

    const getAward = (rank,cssCol) =>{
        let award = ""
        if(awards){
            awards.forEach((item, i)=> {
                if(rank === item.position){
                    /*if(item.award.indexOf("FREESPINS")>-1){
                        let awardVal = item.award.slice(0, item.award.indexOf("FREESPINS"));
                        award = <>{awardVal} <img alt="icon" className={`entain-table-sm-icons ${cssCol}`} src={require('./../assets/images/slots-race-free-spins.svg').default} /></>;
                    }else{
                        award = item.award;
                    }*/
                    award = item.award;
                }
            });
        }
        console.log("award="+award)
        return award;
    }

    const scrollToBottom = () =>{
        //leaderboardRef.current.scrollIntoView({ behavior: 'smooth' });
        //leaderboardRef.current.scrollTop = leaderboardRef.current.scrollHeight;
    }


    const getLeaderboardData = async(newPage) =>{
        if(gettingData === false){
            setGettingData(true);
            setPage(newPage);
            
            return await axios

                .get(`${databaseURL}/api/lobby/leaderboard/${userId}/${gameId}?page=${newPage}&pageSize=${pageCount}`)
                .then((response) => {
                    
                    if(response && response.data){
                        let newleaderboard = [];
                        if(response.data.leaderboard){
                            newleaderboard = response.data.leaderboard;
                            
                        }
                        if(response.data.currentPlayerPosition){
                            setHighlightPlayer(response.data.currentPlayerPosition);
                        }
                        let leaderboardStart = newleaderboard.length === 0 ? 0 : newleaderboard[newleaderboard.length-1].rank
                        if(awards.length > leaderboardStart) {
                            newleaderboard.forEach(leaderboardItem => {
                                leaderboardItem.award = getAward(leaderboardItem.rank, "");
                            });
                
                            for(let i = leaderboardStart; i < awards.length; i++) {
                                newleaderboard.push({award: awards[i].award, rank: awards[i].position});
                            }
                            
                        } 

                        newleaderboard.forEach(leaderboardItem => {
                            if(leaderboardItem.highlight === 1){
                                setHighlightPlayer('');
                                
                            }
                        });
                        setLeaderboardData(newleaderboard);
                        setFinalPage(response.data.finalPage === 1 ? 1 : 0);

                    }
                    setGettingData(false);
                })
                .catch((error) => {
                    setGettingData(false);
                    alert(error)
                    console.log(error);
                })
        }
        
    }

    const moveToNextLeaderboardPage = () => {
        let newPage = page+1
        getLeaderboardData(newPage);

    }

    const backToPreviousLeaderboardPage = () => {
        let newPage = page-1;
        if(newPage > 0){
            
            getLeaderboardData(newPage);
        }
    }
    

    useEffect(() =>{
        getLeaderboardData(1);
    },[])

    return( 
        <div className="leaderboard-side card-section-expand">

            <div className={`card-table ${isShowWeekly ? "show" : "hide"}`}>
                <div className="row table-row title gx-0">
                    <div className="col-2 table-col">
                        <div className="px-3 py-2 text-start position-text">#</div>
                    </div>
                    <div className="col-5 table-col text-start">
                        <div className="px-2 py-2">{t("PLAYER")}<br/><span className="small">{t("POINTS")}</span></div>
                    </div>
                    
                    <div className="col-5 table-col">
                        <div className="px-2 py-2  text-start">{t("PRIZE")}</div>
                    </div>

                </div>
                
                {page === 1 ?
                    <div className="leaderboard bg" style={{paddingTop:"4px", paddingBottom:"4px"}}>
                        <img style={{opacity: 0.1}} alt="caretdown" src={require("../../assets/sky/images/caretup.png")} />
                    </div> 
                :
                    <div className="leaderboard bg" style={{paddingTop:"4px", paddingBottom:"4px"}} onClick={()=>backToPreviousLeaderboardPage()}>
                        <img style={{opacity: 1}}  alt="caretdown" src={require("../../assets/sky/images/caretup.png")} />
                    </div> 
                }
                
                <div className={`leaderboard-size-content-area ${highlightPlayer === '' ? "noHighlight" : "" }`}>
                    {/*(leaderboardData.length>0 || awards.length>0) && leaderboardAndAwards().map((player,index) => (*/}
                    {(leaderboardData && leaderboardData.length>0) && leaderboardData.map((player,index) => ( 
                        <div key={index} className={`row gx-0 table-row ${player.highlight === 1 ? "highlight" : "" }`}>

                            <div className="col-2 table-col">
                                {player.rank === 1 &&
                                    <div className="px-2 py-2 text-start"><img className="positionIcon" alt="icon" src={require('../../assets/sky/images/pos1.svg').default} /></div>
                                }
                                {player.rank === 2 &&
                                    <div className="px-2 py-2 text-start"><img className="positionIcon" alt="icon" src={require('../../assets/sky/images/pos2.svg').default} /></div>
                                }
                                {player.rank === 3 &&
                                    <div className="px-2 py-2 text-start"><img className="positionIcon" alt="icon" src={require('../../assets/sky/images/pos3.svg').default} /></div>
                                }
                                {player.rank !== 1 && player.rank !== 2 && player.rank !== 3 &&
                                    <div className="px-3 py-2 text-start position-text" >{player.rank}</div>
                                }
                            </div>

                            <div className="col-5 table-col">
                                <div className="px-2 py-2 text-start">
                                    <span className="player-name-text">{player.name}</span>
                                    <br />
                                    {player.numPoints != null && <span className="small">{player.numPoints} pts</span>}
                                </div>
                            </div>


                            <div className="col-5 table-col sports-book-table-content-text"><div style={{marginTop:"auto", marginBottom:"auto"}}>
                                {/*<div className="px-2 py-2 text-start">{getAward(player.rank,"")}</div>*/}
                                <div className="px-2 py-2 text-start">{player.award}</div>
                                </div>
                            </div>

                            
                            
                        </div>
                    ))}
                    <div ref={leaderboardRef}></div>
                        
                </div>
                

            </div>

            <div>
                    {highlightPlayer !== '' &&
                    <div className='card-table'>
                        <div className="row gx-0 table-row highlight" style={{width:"100%"}}>

                            <div className="col-2 table-col">
                                <div className="px-3 py-2 text-start">{highlightPlayer.rank}</div>
                            </div>

                            <div className="col-5 table-col">
                                <div className="px-2 py-2 text-start">
                                    <span className="player-name-text">{highlightPlayer.name}</span>
                                    <br />
                                    {highlightPlayer.numPoints != null && <span className="small">{highlightPlayer.numPoints} pts</span>}
                                </div>
                            </div>


                            <div className="col-5 table-col sports-book-table-content-text">
                                {/*<div className="px-2 py-2 text-center">{getAward(highlightPlayer.rank,"")}</div>*/}
                                <div className="px-2 py-2 text-center">{highlightPlayer.award}</div>
                            </div>

                            
                            
                        </div>
                    </div>
                    }
                <div className="leaderboard-footer bg d-flex justify-content-center align-items-center">
                    {finalPage === 1 ? 
                        <div style={{width:"100%", textAlign:"center"}}>
                            <img style={{opacity: 0.1}} alt="caretdown" src={require("../../assets/sky/images/caretdown.png")} />    
                        </div>
                    :
                        <div style={{width:"100%", textAlign:"center"}} onClick={()=>moveToNextLeaderboardPage()}>
                            <img style={{opacity: 1}}  alt="caretdown" src={require("../../assets/sky/images/caretdown.png")} />    
                        </div>
                    }       
                </div>
            </div>
        </div>
        );
};
    
export default LeaderboardBannerSide;