import React, {useEffect} from "react";
import { NavLink } from "react-router-dom"
const Help = () => {
    useEffect (() =>{
        document.body.style.backgroundColor = "#252525";

    },[])
    return(

        <div className="container-page">
            <div className="content-page ps-3 pe-3 pb-3">
                <div className="mytournamentsHeader">Tutorial</div>
                <div className="contentDisplay pb-2">
                    <video id="tutorialsPlayer" playsInline="" webkit-playsinline="" poster=""  muted="" preload="none" controls src="https://cdn.skyvegas-smbattles.com/gameassets/tutorialVideos/slotmasterstutorialhigh.mp4">
                        <source src="" type="video/webm"/>
                        <source src="" type="video/mp4"/>
                    </video>
                </div>
                <h5 className="TutorialHeader">FAQ</h5>
                <div className="page-header">How do I play?</div>
                <div className="page-desc">During the battle, a ‘War Chest' symbol can land on reel 3. This ‘War Chest’ will open, unlocking SlotMasters’ unique weapons system. The player then has 10 seconds to choose a weapon to attack an opponent by selecting their game window after selecting an available weapon, or defend their own score, by selecting a weapon such as ‘Shield’. Players can also choose not to use these weapons and just continue spinning.</div>
                <div className="page-desc">Weapons available and the number of times they may be used will vary by tournament. There are 'defensive' weapons to enable a player to protect their own score and ‘offensive' weapons which will enable a player to attack the score of their opponents. All weapon information can be found on the lobby or in-game during a Warchest Bonus.</div>
                
                <div className="page-header">What does each symbol mean?</div>
                <div className="page-desc">Each symbol represents a certain non-monetary value, the higher the symbols worth the more points you will earn for lining the symbols up on a spin.
                The War Chest Symbol can land on the third reel only and gives the player the option to use one of the defensive or attacking weapons.</div>
                <div className="page-header">Why did the game stop/crash?</div>
                <div className="page-desc">Should the game crash please try refreshing your browser, usually this will solve the issue – Lagging or Crashing of a game is usually down to Network Errors.</div>
                <div className="page-header">Why did I lose my points?</div>
                <div className="page-desc">Points are won and lost during a game by attacking and defending against other players – points do not have a monetary value; they are used to climb the leaderboard and win prizes.</div>
                <div className="page-header">What does the number mean next to the Weapon Symbols?</div>
                <div className="page-desc">Number of uses left on a weapon, depending on the type of game you are paying each weapon will only have a specific number of uses you can use during each game, so choose wisely.</div>
                <div className="page-header">Can I win real money?</div>
                <div className="page-desc">Yes, you can win Freespins, Physical Prizes and Cash from playing Free to Enter tournaments and climbing the leaderboards.</div>
                <div className="page-header">Does it cost me anything to play?</div>
                <div className="page-desc">No, our tournaments are currently free to play.</div>
                <div className="page-header">When can I use the Weapons?</div>
                <div className="page-desc">You can use the weapons when a War Chest Lands on the third reel, be careful not to spin passed the War Chest as it is only active for a limited time.</div>
                <div className="page-header">Why did my weapon not work?</div>
                <div className="page-desc">To use a weapon you need to first click on the attacking or defensive weapon you would like to use, then click the reels of the opponent you would like to use the weapon against, to make sure the weapon works correctly you need to make sure you use the decision before the War Chest Timer runs out.</div>
                <div className="page-header">How do I win?</div>
                <div className="page-desc">Play Battles in each of the tournaments and progress in the leaderboards to win prizes.</div>
                <div className="page-header">How do I collect my winnings?</div>
                <div className="page-desc">Prizes will be automatically credited to your Vegas account after the leaderboard has been calculated, this process can take up to 24 hours.</div>
                <div className="page-header">When will get my winnings?</div>
                <div className="page-desc">Winnings will be credited to your Vegas account and can take up to 24 hours to appear in your account.</div>
                <div className="page-header">Why have I not received my winnings?</div>
                <div className="page-desc">If you have a place on the leaderboard and achieved winnings, this winnings should be placed on your Vegas account shortly, in the case they haven’t appeared in this time, please contact support and they will investigate further.</div>
                <div className="page-header">What is my username?</div>
                <div className="page-desc">Your Username Was chosen on first log in of SlotMasters, this username is final and cannot be changed.</div>
                <div className="page-header">How long does a game last?</div>
                <div className="page-desc">Game Time Depends on the type of Tournament however games usually last between 100 and 300 Seconds.</div>
                <div className="page-header">What to do if the game doesn't load</div>
                <div className="page-desc">On Google Chrome please ensure hardware acceleration is enabled</div>
    
            </div>
        </div>
        
    );
};
  
export default Help;