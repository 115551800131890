const TournamentNav = ({data, hasPrevious,hasNext, navNext, navPrev}) => {
    const timeZone = -(new Date().getTimezoneOffset()*60*1000); //*60*1000

    const startTimeMS = new Date(data.startTime.replace(/ /g,"T")).getTime()+timeZone;
    const endTimeMS = new Date(data.endTime.replace(/ /g,"T")).getTime()+timeZone;
    return(
        <div className="tounament-nav d-flex">
        
            {hasPrevious ?
                <div className={`col-2 text-center`} onClick={navPrev}><img className={`card-arrow-sky`} alt="arrowSVG" src={require('../../assets/sky/images/caretleft_black.png')}/></div>
            :
                <div className={`col-2 text-center disable`}><img className={`card-arrow-sky`} alt="arrowSVG" src={require('../../assets/sky/images/caretleft.png')}/></div>
            }
            <div className="col-8 text-center">{data.name}<br/>
            <span className="date-text">{new Intl.DateTimeFormat("en-GB", {  
                weekday: 'short',
                day: 'numeric',
                hour: "numeric",
                minute: "numeric",
                hour12: true

            }).format(new Date(startTimeMS))} - {new Intl.DateTimeFormat("en-GB", {  
                weekday: 'short',
                day: 'numeric',
                hour: "numeric",
                minute: "numeric",
                hour12: true

            }).format(new Date(endTimeMS))}</span>
            
            </div>
            {hasNext ? 
                <div  className={`col-2 text-center`} onClick={navNext}><img className={`card-arrow-sky`} alt="arrowSVG" src={require('../../assets/sky/images/caretright_black.png')}/></div>
                :
                <div  className={`col-2 text-center disable`}><img className={`card-arrow-sky`} alt="arrowSVG" src={require('../../assets/sky/images/caretright.png')}/></div>
            }
        </div>
    )
}

export default TournamentNav;   