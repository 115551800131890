
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from '../generic/components/ProtectRoute';
import React,{ useContext, useEffect, useLayoutEffect, useState} from "react";
import "../services/i18n";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";

import Terms from "./pages/Terms";
import Login from "../generic/pages/Login";
import MyTournaments from "./pages/MyTournaments";
import Help from './pages/Help';
import NoPage from "../generic/pages/NoPage";
import Maintenance from '../generic/pages/maintenance';
import Register from "../generic/pages/Register"
import AuthProvider from './components/AuthProvider';
import MyBattles from './pages/MyBattles';
import GameDetails from './pages/GameDetails';

import Layout from "./pages/Layout";
import Lobby from "./pages/Lobby";

import './../assets/sky/css/styles.css';
import { useTranslation } from "react-i18next";

const appVersion = "sky.1.0.15";
console.log(appVersion);

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
      document.title = "SlotMasters"
      i18n.changeLanguage("sky");
      // document.documentElement.setAttribute("lang","el"); does not work for some reason
  }, []);
  
  return (
    <>
     <div className='main-bg'></div>
      <BrowserRouter><AuthProvider>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="maintenance" element={<Maintenance />} />
            <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
              <Route index element={<Lobby />} />
              <Route path="mytournaments" element={<MyTournaments />} />
              <Route path="mybattles/:tournamentid" element={<MyBattles />} />
              <Route path="gamedetails/:gameid/:tournamentid" element={<GameDetails />} />
              <Route path="terms" element={<Terms />} />
              <Route path="help" element={<Help />} />
              <Route path="*" element={<NoPage />} />  

            </Route>
          </Routes>
      </AuthProvider></BrowserRouter>

    </>
    
   
  );
}

export default App;
