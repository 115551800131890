import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import UserContext from '../../generic/components/appContext';
import axios from "axios";
import { useTranslation } from 'react-i18next';

const CreateNamePopUp = () =>{
    const [userScreenName, setUserScreenName] = useState('');
    const {onScreenNameUpdate,errorMessage } = useContext(UserContext);
    const [buttonPressed,setButtonPressed] = useState(false)
    const submitLogin =() =>{
        if(!buttonPressed){
            setButtonPressed(true);
            onScreenNameUpdate(userScreenName)
                .then((response) => {
                    if(response===false){
                        setButtonPressed(false);
                    }
                })
            
        }
        
    }

    const inputCheck = (value) => {
        value = value.replace(/[^A-Za-z0-9]/ig, '')
      
        setUserScreenName(value);
    }

    return(<div style={{backgroundColor: "rgba(26,26,26,0.6)",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"}}><div className="register" style={{maxWidth:"343px",width:"100%", top:"200px"}}>
        <div className="form p-3" >
            
            <div className="pb-1 text-start" style={{fontSize:"24px", fontWeight:700, lineHeight:"30px"}}>What would you like to be called?</div>
            <div className="text-start">This will be your player name</div>
            <div className="pb-2 text-start text-muted">(Max 12 Characters)</div>
            
            <input className="mb-2" style={{borderRadius:"40px"}} type="text" maxLength="12" id="screenname" value={userScreenName} placeholder="Enter Your Name" onChange={e => inputCheck(e.target.value)}></input><br/>
            <div className="error-message">{errorMessage}</div>
            <input type="button" className="mb-2 mt-2" style={{width:"100%"}} value="Go to Lobby" onClick={submitLogin}/><br/>
        </div>
    </div></div>);
}

export default CreateNamePopUp;