import { Outlet, Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import React,{ useContext, useState } from "react";
import  UserContext from "../../generic/components/appContext";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CreateNamePopUp from "../../generic/components/CreateNamePopUp";

const Layout = () =>{
    const { userId, userName, userToken,urlToken, onLogout,showPopup,ctaBodyText,ctaBtnText,ctaBtnLink,setShowPopup, balance,playMode,setBalance, teamName, screenNameRequired } = useContext(UserContext);
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

   
    const onUpdateBalance = async () =>{
        return await axios
            .get(`${process.env.REACT_APP_DATABASE_URL}/api/addFound/${userId}/1000`)
            .then((response) => {
                if(response && response.data.message === "ok"){
                    var newBalance = balance + 1000;
                    setBalance(newBalance); 
                }
                
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const toHomeLobbyPage = () => {
        navigate(`/${urlToken}`);
    }
    const resetName = async () => {
        return await axios
            .get(`${process.env.REACT_APP_DATABASE_URL}/api/resetPlayerName/${userId}`)
            .then((response) => {
                if(response && response.data.message === "ok"){
                    alert("name reset complete")
                }
                
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const termsOnClick = (link) =>{
        console.log("redirect")
        window.top.postMessage('SLOT_MASTERS_TERMS' , "*")
        window.parent.postMessage('SLOT_MASTER_TERMS' , "*")  

    }

    const isMyTournamentsSection = () => {
        if(location.pathname.indexOf("mybattles") > -1 || location.pathname.indexOf("gamedetails") > -1) { return true; }

        return false;
        
    }
    return (
        
        <>
            <div className="topbar-content">
                <div className="container-lg d-flex topbar-content-container">
                    <div className="center-column">
                        
                        <nav className="navbar navbar-expand pt-0">
                            <div className="d-flex nav-content">
                                <div>
                                    <img className="reelrivals-logo" alt="logo" src={require('../../assets/sky/images/reelRivalsLogo.png')}/>   
                                </div>
                                <div className="navbar-collapse">
                                    
                                    <div className="navbar-bg pt-1 container-md">
                                        
                                        <ul className="navbar-nav">
                                            
                                            <li className="nav-item">
                                                <NavLink to={ `/${urlToken}`} className={({ isActive }) => (isActive ? "nav-link active d-flex justify-content-center align-items-center" : "nav-link d-flex justify-content-center align-items-center")} ><span className="nav-text"><div className="nav-icon"><img className="icon-default" alt="icon" src={require('../../assets/sky/images/lobbyicons/iconlobby.svg').default} /><img className="icon-active" alt="icon" src={require('../../assets/sky/images/lobbyicons/iconlobbyred.svg').default} /></div>{t("LOBBY")}</span></NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className={({ isActive }) => (isActive || isMyTournamentsSection()? "nav-link active d-flex justify-content-center align-items-center" : "nav-link d-flex justify-content-center align-items-center")}  to={`/mytournaments${urlToken}`}><span className="nav-text"><div className="nav-icon"><img className="icon-default" alt="icon" src={require('../../assets/sky/images/lobbyicons/iconbattles.svg').default} /><img className="icon-active" alt="icon" src={require('../../assets/sky/images/lobbyicons/iconbattlesred.svg').default} /></div>{t("MY_GAMES")}</span></NavLink>
                                            </li>
                                            
                                            
                                            <li className="nav-item">
                                                <NavLink className={({ isActive }) => (isActive ? "nav-link active d-flex justify-content-center align-items-center" : "nav-link d-flex justify-content-center align-items-center")}  to={`/help${urlToken}`}><span className="nav-text"><div className="nav-icon"><img className="icon-default" alt="icon" src={require('../../assets/sky/images/lobbyicons/iconhelp.svg').default} /><img className="icon-active" alt="icon" src={require('../../assets/sky/images/lobbyicons/iconhelpred.svg').default} /></div>{t("HELP")}</span></NavLink>
                                            </li>
                                            {/*<li className="nav-item">
                                                <div className= "nav-link d-flex justify-content-center align-items-center"  style={{cursor:"pointer"}} onClick={() => termsOnClick()}><span className="nav-text"><div className="nav-icon"><img className="icon-default" alt="icon" src={require('../../assets/sky/images/lobbyicons/iconterms.svg').default} /></div>{t("TERMS")}</span></div>
                                            </li>*/}
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </nav>
                        
                    </div>
                    <div className="right-column justify-content-right">
                        <div className="m-1 pt-2">
                            {/*<div className="sm-username" >{userName}<span className=""><img className="sm-logo" alt="logo" src={require('../../assets/images/logo.png')} /></span> </div>*/}
                            <div className="sm-username" ><span style={{paddingRight:"10px", fontWeight:700}}>{userName}</span> Powered by<span className=""><img className="sm-logo" alt="logo" src={require('../../assets/images/logo.png')} /></span> </div>
                            
                        </div>
                        {playMode === 'real' &&  
                            <div className="text-end pe-1" style={{color:"#ffffff", fontWeight:700,fontSize:"1rem"}} onClick={onUpdateBalance}>Balance: £{(Math.round(balance) / 100).toFixed(2)}</div>
                        }
                    </div>
                </div>
            </div>
            <div className="main-content">
                <img className="reelrivals-logo-port" alt="logo" src={require('../../assets/sky/images/reelRivalsLogo.png')} />   
                                
                <div className="container-lg p-0">
                <Outlet />
                
                </div>

            </div>
            
           <div className="empty-bottom-footer">
                <div className="footer-content m-1 pt-2">
                     <div className="sm-username" >Powered by<span style={{paddingLeft: "5px"}}><img className="sm-logo-footer" alt="logo" src={require('../../assets/images/logo.png')} /></span> </div> 
                </div>
           </div>
           {screenNameRequired === true &&
            <CreateNamePopUp />
           }
        </>
    )
};

export default Layout;
