import React,{ useEffect, useState, useContext } from "react";
import TournamentCard from "../components/TournamentCard";
import  UserContext from "../../generic/components/appContext";
import axios from "axios";
import PopUpBox from "../../generic/components/PopUpBox";
import LeaderboardBannerSide from "../components/LeaderboardSide";
import TournamentNav from "../components/TournamentNav";
import ScheduleNav from "../components/ScheduleNav";
import { NavItem } from "react-bootstrap";

const Lobby = () => {
    const [activeGames, setActiveGames] = useState([]); 
    const [upcomingGames, setUpcomingGames] = useState([]); 
    const [completedGames, setCompletedGames] = useState([]); 
    const [showPopup, setShowPopup] = useState('hide')
    const [selectGame, setSelectedGame] = useState();
    const [allGames, setAllGames] = useState();
    const [selectedID, setSelectedID] = useState(0);
    const [scheduleGame, setScheduleGame] = useState(null);
    const [schedulePrevGame, setSchedulePrevGame] = useState(null);
    const [scheduleNextGame, setScheduleNextGame] = useState(null);

    const [popUpShown, setPopUpShown] = useState(false)
    const { userId,databaseURL,lobbyRefreshTime,showPlayerCount } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [ctaData , setCtaData] = useState({});
    const [playerCount, setPlayerCount] = useState(null);

    const getTournamentData = async () =>{
        setLoading(true);
        return await axios
            .get(`${databaseURL}/api/lobby/games/${userId}`)
            .then((response) => {
                let newList = [];
                let completedList = [];
                let upcomingList = [];
                let selected = 0;
                if(response && response.data && response.data.completed){
                    completedList = response.data.completed.sort((a, b) => (a.finished > b.finished) ? 1 : -1);
                    
                }
                setCompletedGames(completedList);
                if(response && response.data && response.data.active){
                    newList = response.data.active.sort((a, b) => (a.finished > b.finished) ? 1 : -1);
                    
                }
                setActiveGames(newList);  
                if(response && response.data && response.data.upcoming){
                    upcomingList = response.data.upcoming.sort((a, b) => (a.finished > b.finished) ? 1 : -1);
                    
                }
                setUpcomingGames(upcomingList);
                if(completedList.length >0){
                    if(newList.length >0){
                        selected = completedList.length;
                    }else{
                        selected = completedList.length-1;
                    }
                }else{
                    selected = 0;
                }
                const fullList = completedList.concat(newList,upcomingList);
                setSelectedID(selected);
                setScheduleGame(fullList[selected]);
                if(selected> 0){
                    setSchedulePrevGame(fullList[selected-1]);
                }else{
                    setSchedulePrevGame(null);
                }
                if(selected +1 < fullList.length){
                    setScheduleNextGame(fullList[selected+1]);
                }else{
                    setScheduleNextGame(null);
                }
                
                setSelectedGame(fullList[selected]);
                setAllGames(fullList);

                setLoading(false);
                if(showPlayerCount === "true"){
                    getPlayerCount();
                }
            })
            .catch((error) => {
                alert(error)
                console.log(error);
            })
    }

    const getPlayerCount = async() =>{
        return await axios
            .get(`${databaseURL}/api/lobby/playerCount`)
            .then((response) => {
                if(response && response.data){
                    setPlayerCount(response.data.count)
                }else{
                    setPlayerCount(null)
                }
                
            })
            .catch((error) => {
                console.log(error);
            })
    }
    
    useEffect(() =>{
        document.body.style.backgroundColor = "#f5f5f5";
        getTournamentData();
        const intervalId = setInterval(() => {
            getTournamentData(true); // Fetch data every 2 minutes 120000
        }, lobbyRefreshTime);
    
        return () => clearInterval(intervalId);
        
    },[])

    const openPopup = (bodyText,btnText,btnLink) =>{ 
        if(!popUpShown){   

            setCtaData({ctaBodyText:bodyText, ctaBtnText:btnText, ctaBtnLink:btnLink})
            setShowPopup('show');
            setPopUpShown(true);
        }
    }

    const closePopup = () =>{
        if(showPopup  === 'hide'){
            setShowPopup('show');  
        }else{
            setShowPopup('hide');
        }

    }

    const nextGame = () =>{
        let selected = selectedID+1
        if(selected < allGames.length){  
            setSelectedID(selected);
            setSelectedGame(allGames[selected]);
        }
    }
    const prevGame = () =>{
        let selected = selectedID-1
        if(selectedID>0){ 
            setSelectedID(selected);
            setSelectedGame(allGames[selected]);
        }
        
    }


    return( 
        <div className="lobby-content justify-content-center">

            <div className="center-column">

            {loading ? ( <div className="loading-text"></div>
            ):(
                <>
                {selectGame ? 
                
                 <TournamentCard data={scheduleGame} openPopup={openPopup} reloadHandler={getTournamentData} hasNext={selectedID +1 < allGames.length} hasPrevious={selectedID> 0} navNext={scheduleNextGame} navPrev={prevGame} playerCount={playerCount}/>  
                :
                <div className="loading-text pt-4" >There are currently no tournaments in progress.</div>
            
                }
                
                </>
            )}
            <PopUpBox show={showPopup} data={ctaData} close={closePopup} />

            {selectGame && selectGame.bannerUrl && <div className="iframe-container">
                {/* make it dynamic, pick the url from the response body.BannerUrl */}
                <iframe className="iframe-content-responsive" allowtransparency="true" src={selectGame.bannerUrl} marginWidth="0" marginHeight="0" hspace="0" vspace="0"  frameBorder="0" scrolling="no"></iframe>
            </div>}

            
            </div>
            <div className="right-column">
            {selectGame && 
                <div  className="container-md mt-2">
                    <ScheduleNav data={scheduleGame} prevGame={schedulePrevGame}  nextGame={scheduleNextGame}/>
                    <TournamentNav data={selectGame} cardState={""} hasPrevious={selectedID> 0} hasNext={selectedID +1 < allGames.length} navNext={nextGame} navPrev={prevGame}/>
                    <LeaderboardBannerSide key={selectGame.id} data={selectGame} cardState={""} />
                </div>
            }
            </div>
        </div>
    );
};
  
export default Lobby;