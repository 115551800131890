import React,{ useEffect }  from "react";
const Terms = () => {
    useEffect (() =>{
        document.body.style.backgroundColor = "#252525";

    },[])
    return (
        <div className="container-page">
            <div className="content-page ps-3 pe-3 pb-3">
                <div className="mytournamentsHeader">Terms</div>
            </div>
        </div>
    );
};
  
export default Terms;