import React,{ useEffect, useState,useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import  UserContext from "../../generic/components/appContext";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MyTournaments = () => {
    let [myTournaments, setMyTournaments] = useState();
    const { userId, userToken, urlToken,databaseURL } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const fetchMyTournaments = async () =>{
        setLoading(true);
        return await axios
            .get(`${databaseURL}/api/lobby/mytournaments/${userId}`)
            .then((response) => {
                if(response && response.data && response.data.myTournaments){
                    setMyTournaments(response.data.myTournaments); 
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() =>{
        fetchMyTournaments();
        document.body.style.backgroundColor = "#252525";
    },[])

    return (
        <div className="container-page">
            <div className="content-page">
                <div className="row gx-0">
                    <div className="col-12">
                        <div className = "mytournamentsHeader">{t("MY_TOURNAMENTS") }</div>
                    </div>
                </div>
                <div className="card-table">
                    <div className="row gx-0 title table-row">
                        <div className="col-1 table-col">
                            <div className="p-1 title">#</div>
                        </div>
                        <div className="col-6 table-col">
                            <div className="p-1 title text-start">{t("NAME") }<br/><span className="small">{t("PRIZE")}</span></div>
                        </div>
                        <div className="col-3 table-col">
                            <div className="p-1 title text-start">{t("POSITION")}<br/><span className="small">{t("STATUS")}</span></div>
                        </div>
                        <div className="col-2 table-col">
                            <div className="p-1 title">{t("BATTLES")}</div>
                        </div>
                    </div>

                    {myTournaments && myTournaments.map((data,index) => (
                        
                        <div key={index} className="row gx-0 table-row ">
                            <div className="col-1 table-col">
                                <div className="p-1">{index+1}</div>
                            </div>
                            <div className="col-6 table-col">
                                <div className="p-1 text-start">{data.name}<br/><span className="small">{data.prize}</span></div>
                            </div>
                            <div className="col-3 table-col">
                                <div className="p-1 text-start">{data.yourPosition === 0 ? "-" : data.yourPosition}<br/><span className="small">{data.complete === 1 ? t("COMPLETED") : t("ACTIVE")}</span></div>
                            </div>
                            
                            <div className="col-2 table-col">
                                <div className="p-1">
                                    <Link to={'/mybattles/'+data.id+urlToken} className="page-button">{t("VIEW")}</Link>
                                </div>
                            </div>
                        </div>
                        ))
                    }
                    </div>
                    <div className="leaderboard-footer bg d-flex justify-content-center align-items-center">
                        <i className="icon bi-chevron-down fs-5"></i>
                    </div>
            </div>
            
    </div>
    );
};
  
export default MyTournaments;