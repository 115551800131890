import { useTranslation } from "react-i18next";

import CountdownTimer from "../../generic/components/Timer/CountdownTimer";

const StartEndsInBlock = (props) =>{
    const {startTimeMS, endTimeMS,startTime,endTime,finished, timerFinishedHandler, nextGameStart,numGamesLeft} = props;

    const { t } = useTranslation();
    if(finished){
        if(nextGameStart !== ""){
            return(
                <div className="countdown-timer d-flex">
                    <div className="col-5">
                        <div className="starts-text">{t("NEXT_TOURNAMENT_STARTS")}</div>
                    </div>
                    <div className="col-7">
                        <CountdownTimer targetDate={nextGameStart} completeCallback={timerFinishedHandler}/>
                    </div>
                </div>
            )
        }else{
            return (<div className="pt-3">  
                <div className="card-detail">{t("FINISHED")}</div></div>);
        }
    }
    if(endTimeMS && endTimeMS<Date.now()){ 
        if(nextGameStart !== ""){
            return(
                <div className="countdown-timer d-flex">
                    <div className="col-5">
                        <div className="starts-text">{t("NEXT_TOURNAMENT_STARTS")}</div>
                    </div>
                    <div className="col-7">
                        <CountdownTimer targetDate={nextGameStart} completeCallback={timerFinishedHandler}/>
                    </div>
                </div>
            )
        }else{
            return (<div className="pt-3">
            <div className="card-detail">{t("FINISHED")}</div></div>);
        }
    }else if(endTimeMS && startTimeMS<Date.now() && endTimeMS>Date.now()){
        /*return(
            <div className="pt-2"><div className="card-heading">{t("ENDS")}</div> <CountdownTimer targetDate={endTimeMS} completeCallback={timerFinishedHandler}/></div>
        )*/
        if(numGamesLeft === 0 && nextGameStart !== ""){
            return(
                <div className="countdown-timer d-flex">
                    <div className="col-5">
                        <div className="starts-text">{t("NEXT_TOURNAMENT_STARTS")}</div>
                    </div>
                    <div className="col-7">
                        <CountdownTimer targetDate={nextGameStart} completeCallback={timerFinishedHandler}/>
                    </div>
                </div>
            )
        }else{
            return (<><div className="card-heading"></div>   
            <div className=""></div></>)
        }

    }else if(startTimeMS  && startTimeMS>Date.now()){
        return(
            <div className="countdown-timer d-flex">
                <div className="col-5">
                    <div className="starts-text">{t("NEXT_TOURNAMENT_STARTS")}</div>
                </div>
                <div className="col-7">
                    <CountdownTimer targetDate={startTimeMS} completeCallback={timerFinishedHandler}/>
                </div>
            </div>
        )
    }else{
        return (<><div className="card-heading"></div>   
            <div className=""></div></>)
    }
}

export default StartEndsInBlock;