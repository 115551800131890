import WeaponBanner from './WeaponsBanner';
//import LeaderboardBanner from './LeaderboardBanner';
//import LeagueBanner from './LeagueBanner';
import React,{ useEffect, useLayoutEffect, useState,useContext, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import UserContext from '../../generic/components/appContext';

import StartButton from './StartButton';
import StartEndsInBlock from './StartEndsInBlock';

const TournamentCard = ({data,reloadHandler, hasNext, hasPrevious,openPopup, navNext, navPrev, playerCount}) => {

    const timeZone = -(new Date().getTimezoneOffset()*60*1000); //*60*1000

    const startTimeMS = new Date(data.startTime.replace(/ /g,"T")).getTime()+timeZone;
    const endTimeMS = new Date(data.endTime.replace(/ /g,"T")).getTime()+timeZone;

    const nextGameStartTime = navNext ? new Date(navNext.startTime.replace(/ /g,"T")).getTime()+timeZone : "";

    const clientName= data.clientName;

    const {userToken,teamName, cpuLevelUp} = useContext(UserContext);
    const finished = data.finished === 1 ? true : false;;
    const { t } = useTranslation();
   
    //  disable the upsell message
    // if(startTimeMS > Date.now()){
    //     // cardState = 'upcoming';
    // }else if(endTimeMS < Date.now()){
    //     // cardState = 'completed';
    // }else{
    //     // cardState = '';
    //     if(data.numGamesLeft === 0){
    //         if(data.journeyEndText !== ""){
    //             openPopup(data.journeyEndText,data.ctaText ? data.ctaText : "",data.clickthroughUrl?data.clickthroughUrl:"");
    //         }
    //     }
    // }


    const getCompletedBattles = (totalGames, gamesLeft) => {
        const icons = [];
        let completedGames = totalGames - gamesLeft;
        for( let i=0;i<completedGames;i++){
            icons.push(<img key={i} className="completedIcon" alt="icon" src={require('../../assets/sky/images/battlesIconused.svg').default} />);
        }
        for( let i=0;i<gamesLeft;i++){
            icons.push(<img key={completedGames+i} className="completedIcon" alt="icon" src={require('../../assets/sky/images/battlesIcon.svg').default} />);
        }
        return icons;
    }


    const timerFinishedHandler = () =>{
        reloadHandler();
    }

    const showLifeTime = (time) =>{
        let mins = Math.floor(time / 60);

        let secs = time % 60 ;
        if (mins===0)
            return `${secs} ${t("SECONDS")}` ;
        else if (secs===0)
            return mins === 1 ? `${mins} ${t("MINUTE")}` : `${mins} ${t("MINUTES")}` ;
        else
            return mins === 1 ? `${mins} ${t("MINUTE")} : ${secs} ${t("SECONDS")}` : `${mins} ${t("MINUTES")} : ${secs} ${t("SECONDS")}` ;
    }    
 
    return( 
        // {
        //     if(startMS > Date.now()){
        //         setCardState('upcoming');
        //     }else if(endMS < Date.now()){
        //         setCardState('completed');
        //     }else{
    
        //         setCardState('');
        //         if(data.numGamesLeft === 0){
        //             if(data.journeyEndText !== ""){
        //                 openPopup(data.journeyEndText,data.ctaText ? data.ctaText : "",data.clickthroughUrl?data.clickthroughUrl:"");
        //             }
        //         }
        //     }
        // }
        <div className={`tournament-card mb-3`} style={{backgroundImage: data.gameBackground !== "" ? `url(${data.gameBackground})` : "none", backgroundRepeat: "no-repeat", backgroundPosition:"top center", backgroundSize:"cover"}}>
            <div className="">
                <div className="card-body">
                    <div className="card-row">
                        <div className="col-12 text-center">
                            <div className="card-row">
                                <div className="col-4 text-start">
                                    {playerCount !== null &&
                                    <div className="card-detail" style={{color:"#ffffff"}}>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            <img style={{width:"18px"}} alt="icon" src={require('../../assets/sky/images/myaccount.svg').default} /> <span>{playerCount}</span>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className="col-8 text-end">
                                    
                                    {data.numPlays > 5 &&
                                        <div className="card-detail" >
                                            <div style={{display:"flex", alignItems:"center"}}>
                                                <span>{t("BATTLES_LEFT")}: </span>{data.numGamesLeft < 0 ? '0' : (data.numPlays-data.numGamesLeft)}/{data.numPlays}</div>
                                            </div>
                                    }
                                    {data.numPlays <= 5 &&                                    
                                        <div className="card-detail" >
                                            <div style={{display:"flex", alignItems:"center"}}>
                                            <span className="">{t("BATTLES_LEFT")}:</span>{getCompletedBattles(data.numPlays,data.numGamesLeft)}</div>
                                            </div>
                                    }
                                   
                                </div>
                            </div>
                            <div className="card-image-parent">
                                <img className="card-image" alt="logo" src={data.gameIcon} />
                            </div>
                            <div className="text-center pb-3">
                                <div className="card-detail">{t("DURATION")}: <span className='card-value'>{showLifeTime(data.lifeTime)}</span></div>
                            </div>
                          
                            <div className="text-center"  style={{height:"78px"}}>
                                <StartButton numGamesLeft={data.numGamesLeft} startTimeMS={startTimeMS} endTimeMS={endTimeMS} finished={finished} nextGameStart={nextGameStartTime} clientUrl={data.clientUrl} userToken={userToken} cpuLevelUp={cpuLevelUp}/>
                                <StartEndsInBlock numGamesLeft={data.numGamesLeft} startTimeMS={startTimeMS} endTimeMS={endTimeMS} finished={finished} nextGameStart={nextGameStartTime} timerFinishedHandler={()=>timerFinishedHandler()} />
                            </div>
                        </div>   
                        
                        
                    </div>
                </div>
                
            </div>
            
            {data.weapons.length>0 &&
            <WeaponBanner data={data.weapons}  clientName={clientName}/>
            }       
            
            
        </div>
    );
};
    
export default TournamentCard;
