const ScheduleNav = ({data, nextGame, prevGame}) => {
    return(
        <div className="schedule-nav d-flex mb-3">
            <div className="col-4 pe-1"><ScheduleItem data={prevGame}/></div>
            <div className="col-4 ps-1 pe-1"><ScheduleItem data={data}/></div>
            <div className="col-4 ps-1"><ScheduleItem data={nextGame}/></div>
            
        </div>
    )
}

export default ScheduleNav;

const ScheduleItem = ({data}) =>{
    
    if(data && data.name){
        const timeZone = -(new Date().getTimezoneOffset()*60*1000); //*60*1000
        const startTimeMS = new Date(data.startTime.replace(/ /g,"T")).getTime()+timeZone;
        const endTimeMS = new Date(data.endTime.replace(/ /g,"T")).getTime()+timeZone;

        let status = "NOW";
        if(data.finished === 1){
            return <div className="schedule-item previous"><div className="schedule-prev">PREVIOUS</div><div className="game-name">{data.name}</div><div><span className="game-date">{new Intl.DateTimeFormat("en-GB", {  
                weekday: 'short',
                day: 'numeric',
                hour: "numeric",
                hour12: true
            }).format(new Date(startTimeMS))}</span></div></div>
        }else if(endTimeMS && endTimeMS<Date.now()){ 
            return <div className="schedule-item previous"><div className="schedule-prev">PREVIOUS</div><div className="game-name">{data.name}</div><div><span className="game-date">{new Intl.DateTimeFormat("en-GB", {  
                weekday: 'short',
                day: 'numeric',
                hour: "numeric",
                hour12: true
            }).format(new Date(startTimeMS))}</span></div></div>
        }else if(startTimeMS  && startTimeMS>Date.now()){
            return <div className="schedule-item"><div className="schedule-comingup">COMING UP</div><div className="game-name">{data.name}</div><div><span className="game-date">{new Intl.DateTimeFormat("en-GB", {  
                weekday: 'short',
                day: 'numeric',
                hour: "numeric",
                hour12: true
            }).format(new Date(startTimeMS))}</span></div></div>
        }else{
            return <div className="schedule-item"><div className="schedule-now">NOW</div><div className="game-name">{data.name}</div><div><span className="game-date">{new Intl.DateTimeFormat("en-GB", {  
                weekday: 'short',
                day: 'numeric',
                hour: "numeric",
                hour12: true
            }).format(new Date(startTimeMS))}</span></div></div>
        }
        
    }else{
        return(
            <div className="schedule-item previous"></div>
        );
    }
}
