import { useParams } from "react-router-dom";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import  UserContext from "../../generic/components/appContext";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MyBattles = () => {
  const { userId,userToken,urlToken,databaseURL } = useContext(UserContext);
  const paraData = useParams();
  const [id, setId] = useState(paraData.tournamentid);
  const [tournamentData, setTournamentData] = useState();
  const [tournamentName, setTournamentName] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [timeZone, setTimeZone] = useState(-(new Date().getTimezoneOffset()*60*1000)); //*60*1000


    const getMyBattles = async () =>{
        setLoading(true);
        return await axios
            .get(`${databaseURL}/api/lobby/mytournaments/games/${userId}/${id}`)
            .then((response) => {
                if(response && response.data && response.data.myBattles){
                    setTournamentData(response.data.myBattles);
                    setTournamentName(response.data.myBattles[0].name);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect (() =>{
        getMyBattles();
        document.body.style.backgroundColor = "#252525";

    },[])


    return (
      <div className="container-page">
            <div className="content-page">
                <div className="row gx-0 mb-1">
                    <div className="col-3">
                        
                    </div>
                    <div className="col-6">
                        <div className="mytournamentsHeader">{t("MY_GAMES")}</div>
                    </div>
                    <div className="col-3">
                        <div className="mytournamentsHeader text-end"><Link className="text-link" to={`/mytournaments${urlToken}`}>{t("BACK")}</Link></div>
                    </div>
                </div>
                <div className="card-table">
                    <div className="row gx-0 title table-row">
                        <div className="col-4 table-col">
                            <div className="p-1 ps-3 title text-start">{t("ID")}<br/><span className="small">{t("DATE")}</span></div>
                        </div>
                        <div className="col-6 table-col">
                            <div className="p-1 title text-start">{t("NAME")}<br/><span className="small">{t("WINNER")}</span></div>
                        </div>
                        <div className="col-2 table-col">
                            <div className="p-1 pe-3 title">{t("ACTION")}</div>
                        </div>
                    </div>
               
                    {tournamentData && tournamentData.map((data,index) => (
                        <div key={index} className="row gx-0 table-row ">
                            <div className="col-4 table-col">
                                <div className="p-1 ps-3 text-start">{data.id}<br/><span className="small">{new Intl.DateTimeFormat("en-GB", { 
                                            year: "numeric",
                                            month: "numeric",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: false,
                                        }).format(new Date(data.startTimeDate.replace(/ /g,"T")).getTime()+timeZone)}</span></div>
                            </div>
                            <div className="col-6 table-col">
                                <div className="p-1 text-start">{tournamentName}<br/><span className="small">{data.winnerName} {data.totalWinnings}</span></div>
                            </div>
                            
                            <div className="col-2 table-col">
                                <div className="p-1 pe-3 "><Link to={`/gamedetails/${data.id}/${id}${urlToken}`} className="page-button">{t("VIEW")}</Link></div>
                            </div>
                        </div>
                        ))
                    }
                    </div>
                    <div className="leaderboard-footer bg d-flex justify-content-center align-items-center">
                        <i className="icon bi-chevron-down fs-5"></i>
                    </div>
            </div>
      </div>
    );
    
  };
  
  export default MyBattles;